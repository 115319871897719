import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 引入wowjs效果
import animated from "animate.css";
//使用
Vue.use(animated);
import "wowjs/css/libs/animate.css";
import "swiper/swiper.min.css";
import { WOW } from "wowjs";
Vue.prototype.WOW = WOW;
Vue.use(ElementUI);
// Vue.use(animated);
Vue.config.productionTip = false;
Vue.prototype.$baseUrl = "http://zudao.wuyi.link"

// router.beforeEach((to, from, next) => {
//   // chrome
//   document.body.scrollTop = 0;
//   // firefox
//   document.documentElement.scrollTop = 0;
//   // safari
//   window.pageYOffset = 0;
//   next();
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
