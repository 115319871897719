import Vue from "vue";
import VueRouter from "vue-router";
let Index = () => import("../components/index.vue");
let Service = () => import("../components/service.vue");
let Cases = () => import("../components/cases.vue");
let About = () => import("../components/about.vue");
let Call = () => import("../components/call.vue");
let Qualifications = () => import("../components/qualifications.vue");
let Details = () => import("../components/details.vue");
//避免重复点击当前页面的路径提示异常，不影响程序的正常执行
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    component: Index,
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
  },
  {
    path: "/cases",
    name: "Cases",
    component: Cases,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/call",
    name: "Call",
    component: Call,
  },
  {
    path: "/qualifications",
    name: "Qualifications",
    component: Qualifications,
  },
  {
    path: "/details",
    name: "Details",
    component: Details,
  },
];
const scrollBehavior = (to, from, savedPosition) => {
  // console.log(from);
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y: 0, top: 0, left: 0 };
};

const router = new VueRouter({
  routes,
  scrollBehavior,
});

export default router;
