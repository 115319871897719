import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
let store = new Vuex.Store({
  state: {
    num: 10,
    arr: [
      { id: 1, name: '水壶', price: 28.9 },
      { id: 2, name: '啤酒', price: 12.9 },
    ],
  },
  getters: {
    getLength(state) {
      // 使用闭包
      return (n) => {
        console.log(state)
        console.log(n)
        return state.arr[n]
      }
    },
  },
  actions: {
    subNum(st, n) {
      // 调用mutations中的函数subNum ，st是store对象
      st.commit('subNum', n)
    },
  },
  mutations: {
    subNum(context, n) {
      context.num -= n
    },
    addNum(context, n) {
      console.log(context, n)
      context.num += n
    },
  },
})
export default store
